<template>
	<div class="withdraw">
		<div class="searchw">
      <Form inline :label-width="52">
        <FormItem label="用户ID" :label-width="53" style="width: 150px">
          <Input maxlength="10" v-model="posdata.id" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="用户手机" :label-width="66" style="width: 166px">
          <Input maxlength="11" v-model="posdata.user_phone" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="用户昵称" :label-width="66" style="width: 166px">
          <Input maxlength="30" v-model="posdata.wx_name" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="收款姓名" :label-width="66" style="width: 166px">
          <Input maxlength="30" v-model="posdata.realname" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="收款账号" :label-width="66" style="width: 250px">
          <Input maxlength="30" v-model="posdata.account" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="提现状态" :label-width="66" style="width: 140px">
          <Select transfer v-model="posdata.status" :class="[posdata.status=='0'?'no-sel':'']" @on-change="search">
            <Option value="0">不限</Option>
            <Option v-for="(p,k) in cfg.status" :value="k" :key="k">{{p}}</Option>
          </Select>
        </FormItem>
        <FormItem label="收款类型" :label-width="66" style="width: 140px">
          <Select transfer v-model="posdata.account_type" :class="[posdata.account_type=='0'?'no-sel':'']" @on-change="search">
            <Option value="0">不限</Option>
            <Option v-for="(p,k) in cfg.account_type" :value="k" :key="k">{{p}}</Option>
          </Select>
        </FormItem>
        <FormItem label="提现类型" :label-width="66" style="width: 128px">
          <Select transfer v-model="posdata.wtype" :class="[posdata.wtype=='0'?'no-sel':'']" @on-change="search">
            <Option value="0">不限</Option>
            <Option v-for="(p,k) in cfg.wtype" :value="k" :key="k">{{p}}</Option>
          </Select>
        </FormItem>
        <FormItem label="身份证姓名" :label-width="80" style="width: 178px">
          <Input maxlength="30" v-model="posdata.name" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="身份证号码" :label-width="80" style="width: 228px">
          <Input maxlength="18" v-model="posdata.rnum" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="姓名一致" :label-width="66" style="width: 140px">
          <Select transfer v-model="posdata.nameeq" :class="[posdata.nameeq=='0'?'no-sel':'']" @on-change="search">
            <Option value="0">不限</Option>
            <Option v-for="(p,k) in cfg.nameeq" :value="k" :key="k">{{p}}</Option>
          </Select>
        </FormItem>
        <FormItem label="" :label-width="10" style="width: 210px">
          <Select transfer v-model="posdata.pid" :class="[posdata.pid=='0'?'no-sel':'']" style="width: 90px;" @on-change="search">
            <Option value="0">不限平台</Option>
            <Option v-for="(p,k) in cfg.platform" :value="k" :key="k">{{p}}</Option>
          </Select>
          <Input maxlength="20" v-model="posdata.num" placeholder="平台UID" style="width: 103px; margin-left: 5px;" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="发票KEY" :label-width="66" style="width: 186px">
          <Input maxlength="30" v-model="posdata.invkey" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="物流单号" :label-width="66" style="width: 186px">
          <Input maxlength="30" v-model="posdata.wlnum" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="申请时间" :label-width="66" style="width: 342px">
          <DatePicker transfer type="date" format="yyyy-MM-dd" placeholder="起始时间" style="width: 118px" @on-change="seldate($event,'start_time')"></DatePicker>
          <span style="color: #515a6e;"> ~ </span>
          <DatePicker transfer type="date" format="yyyy-MM-dd" placeholder="结束时间" style="width: 118px" @on-change="seldate($event,'end_time')"></DatePicker>
        </FormItem>
        <FormItem label="" :label-width="0">
          <Button type="default" icon="ios-search" @click="search" @keyup.13="search">搜索</Button>
        </FormItem>
			</Form>
		</div>
    <div class="batchbtn">
      <Button size="small" type="success" @click="examWith(-1,3)">批量确认打款</Button>
      <Divider type="vertical" />
      <Button size="small" type="primary" @click="examWith(-1,2)">批量审核通过</Button>
      <Divider type="vertical" />
      <Button size="small" type="warning" @click="examWith(-1,4)">批量拒绝/驳回</Button>
    </div>
		<Table size="small" :columns="columns" :data="data.data" :loading="table_loading" @on-selection-change="selectMulti">
      <template slot-scope="row" slot="userinfo">
        <div class="uinfow">
          <div class="headimg"><viewer :options="{'url': 'data-source'}"><img :src="row.row.head_img+'?x-oss-process=style/headimg'" :data-source="row.row.head_img" /></viewer></div>
          <div class="uinfo">
            <div>昵称：<span>{{row.row.wx_name||'--'}}</span></div>
            <div>手机：<span>{{row.row.user_phone||'--'}}</span></div>
            <div>性别：<span>{{row.row.gender||'--'}}</span></div>
            <div v-for="(p,k) in row.row.ninfo" :key="k">{{cfg.platform[p.pid]||''}}UID：<span>{{p.num||'--'}}</span></div>
            <div>等级：<span>{{cfg.level[row.row.level_id]||'--'}}</span></div>
            <div>团队业绩：<span>{{row.row.team_achiev||'0.00'}}</span>元</div>
            <div>个人业绩：<span>{{row.row.achiev||'0.00'}}</span>元</div>
            <div>昨日业绩：<span>{{row.row.yes_achiev||'0.00'}}</span>元</div>
            <div>佣金余额：<span>{{row.row.money||'0.00'}}</span>元</div>
          </div>
        </div>
      </template>
      <template slot-scope="row" slot="money">
        <div class="sinlis">提现金额：<span>{{row.row.wmoney||'0.00'}}</span></div>
        <div class="sinlis">手续费：<span>{{row.row.wfee||'0.00'}}</span></div>
        <div class="sinlis">实到账：<span>{{row.row.truemoney||'0.00'}}</span></div>
      </template>
      <template slot-scope="row" slot="account">
        <div class="sinlis">账号类型：<span>{{row.row.wtype?'企业':'个人'}}{{row.row.account_typetxt||''}}</span></div>
        <div class="sinlis" v-if="row.row.account_type==3">银行：<span>{{row.row.bank_name||''}}</span></div>
        <div class="sinlis" v-if="row.row.account_type!=2">账号：<span>{{row.row.account||''}}</span></div>
        <div class="wxcode" v-else>收款码：<img v-viewer :src="row.row.wxcode" /></div>
        <div class="sinlis" v-if="!row.row.wtype && row.row.account_type==3">预留手机：<span>{{row.row.mobile||''}}</span></div>
      </template>
      <template slot-scope="row" slot="realname">
        <div class="sinlis">提现姓名：<span :title="row.row.realname">{{row.row.realname||''}}</span></div>
        <div class="sinlis">真实姓名：<span>{{row.row.name||'--'}}</span></div>
        <div class="sinlis">身份证号：<span class="num" @dblclick="changeNum(row.index)">{{row.row.numtxt||''}}</span></div>
        <template v-if="row.row.wtype && row.row.invtotm>0">
          <div class="sinlis" v-if="row.row.invtype==1">发票KEY：<span>{{row.row.invkey||''}}</span></div>
          <div class="sinlis">发票类型：<span>{{row.row.invtype==1?'纸质发票':'电子发票'}}</span></div>
          <div class="sinlis">发票金额：<span>{{row.row.invtotm||'0.00'}}</span></div>
          <div class="sinlis" v-if="row.row.invtype==1 && row.row.wlnum!=''">物流公司：<span>{{row.row.wlcom||''}}</span></div>
          <div class="sinlis" v-if="row.row.invtype==1 && row.row.wlnum!=''">物流单号：<span>{{row.row.wlnum||''}}</span></div>
        </template>
      </template>
      <template slot-scope="row" slot="status">
        <div :class="'status status'+row.row.status">{{row.row.statustxt}}</div>
        <div class="sinlis" v-if="row.row.remark!=''">微信：<span>{{row.row.remark||''}}</span></div>
        <div class="sinlis" v-if="row.row.status==4">驳回原因：<span>{{row.row.reason||''}}</span></div>
      </template>
      <template slot-scope="row" slot="time">
        <div class="sinlis">申请时间：<span>{{row.row.add_time}}</span></div>
        <div class="sinlis" v-if="row.row.status==2">审核时间：<span>{{row.row.check_time||''}}</span></div>
        <div class="sinlis" v-else-if="row.row.status==3">打款时间：<span>{{row.row.succ_time||''}}</span></div>
        <div class="sinlis" v-else-if="row.row.status==4">驳回时间：<span>{{row.row.check_time||''}}</span></div>
      </template>
      <template slot-scope="row" slot="action">
        <Button class="cbtn" type="error" size="small" v-if="row.row.status<3" @click="examWith(row.row.id,4)">审核拒绝</Button>
        <Button class="cbtn" type="success" size="small" v-if="row.row.status==1" @click="examWith(row.row.id,2)">审核通过</Button>
        <Button class="cbtn" type="success" size="small" v-else-if="row.row.status==2" @click="examWith(row.row.id,3)">确认打款</Button>
        <Button class="cbtn" type="info" size="small" v-if="row.row.wtype==1" @click="showInvmsg(row.index)">发票信息</Button>
        <template v-if="row.row.status>2 && row.row.wtype==0">--</template>
      </template>
		</Table>
    <div class="footer-m">
      <div class="fexport-btn">
        <Button icon="md-download" @click="export_excel" :loading="export_loading">导出Excel</Button>
      </div>
      <Page class-name="pagew page-r" :total="data.total" :current="data.current_page" :page-size="data.per_page" :page-size-opts="[15,30,50,100]" show-sizer show-total @on-change="page" @on-page-size-change="change_pagesize" />
    </div>
    <Modal v-model="showM" width="360" class-name="withdraw-exam" :title="ckdata.ckstatus == 4?'驳回或拒绝原因':'提示'" :loading="examMLoading" @on-ok="examCom">
      <Input v-if="ckdata.ckstatus == 4" v-model="ckdata.reason" autofocus type="textarea" :autosize="{minRows: 3,maxRows: 3}" placeholder="请输入驳回或拒绝原因..." />
      <div v-else>{{(ckdata.selid.length>1?'共 ' + ckdata.selid.length + ' 条数据，确认批量':'确认') + (ckdata.ckstatus == 2?'审核通过':'打款完成了') + '吗？'}}</div>
    </Modal>
    <Modal v-model="showInv" width="700" class-name="inv-out" title="发票信息" footer-hide>
      <div class="inv-table">
        <div class="invhead">
          <span style="width: 28%">发票号</span>
          <span>发票金额</span>
          <span>发票张数</span>
          <span>发票文件</span>
          <span>合计</span>
        </div>
        <div class="invlis" v-for="(p,key) in invdata.invdata" :key="key">
          <span style="width: 15%">{{p.invnum||''}}</span>
          <span>￥{{p.invmoney||''}}</span>
          <span>{{p.mintot||''}}</span>
          <span v-if="invdata.invtype==1">纸质</span>
          <span v-else><a :href="p.invfile" target="_blank">下载</a></span>
          <span>￥{{p.mintotm||''}}</span>
        </div>
        <div class="invlis">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span>￥{{invdata.invtotm||''}}</span>
        </div>
      </div>
    </Modal>
	</div>
</template>

<script>
	import XLSX from "xlsx";
  export default {
		name: "allwithraw",
		data(){
			return{
				formData:{user_phone:'',add_time:'',status:''},
        columns:[
          {type: 'selection',width: 40,align: 'center'},
          {title: '用户信息',slot: 'userinfo',minWidth:200},
          {title: '提现金额',slot: 'money',minWidth:130},
          {title: '收款账号',slot: 'account',minWidth:200},
          {title: '账户名/发票',slot:'realname', minWidth:220},
          {title: '状态/微信',slot:'status', minWidth:120},
          {title: '时间',slot:'time',minWidth:200},
          {title: '操作',slot:'action',width:90},
        ],
        data:{},
        cfg:{status: {},account_type:{},platform: {},level:{}},
        table_loading:false,
        export_loading:false,
        ckdata:{selid:[],ckstatus:0,reason:''},
        showM:false,
        examMLoading:true,
        showInv:false,
        invdata:{invtype:'',invtotm:'',invdata:[]},
        posdata:{
          id:'',
          user_phone:'',
          wx_name:'',
          realname:'',
          account:'',
          status:'0',
          account_type:'0',
          wtype:'0',
          name:'',
          rnum:'',
          pid:'0',
          num:'',
          nameeq:'0',
          invkey:'',
          wlnum:'',
          start_time:'',
          end_time:'',
          page:1,
          page_size:15
        }
			}
		},
		created() {
      let query={};
      if(typeof this.$route.query.status!="undefined"){
        this.posdata.status=this.$route.query.status;
        query.status=this.$route.query.status||'0';
      }
      this.$router.push({query});
      this.$store.commit('add_tagsview',this.$route);
			this.init();
		},
    watch:{
      '$route.query':function (query){
        if(query.newpage==1){
          query={'status':query.status||'0'};
          Object.assign(this.posdata,query);
          this.$router.push({query});
          this.$store.commit('add_tagsview',this.$route);
          this.init();
        }
      }
    },
		methods:{
			init(){
				var _this = this;
        this.ckdata.selid=[];
				this.requestApi('/adm/get_waitwithraw.html',this.posdata).then(function(res){
          if(res.status==1){
            _this.cfg=res.cfg;
            _this.data  = res.data;
          }
				})
			},
      /**
       * 搜索
       */
      search:function (){
        this.posdata.page=1;
        this.init();
      },
      /**
       * 翻页
       */
      page:function (page){
        this.posdata.page=page;
        this.init();
      },
      /**
       * 切换分页条数
       */
      change_pagesize:function (size){
        this.posdata.page_size=size;
        if(this.posdata.page==1) this.init();
      },
      /**
       * 身份证号码 加 ** /原样
       */
      changeNum:function (key){
        let num=this.data.data[key].num,numtxt=this.data.data[key].numtxt;
        if(num!=numtxt) this.data.data[key].numtxt=num;
        else this.data.data[key].numtxt=num.substr(0,6)+'********'+num.substr(-4,4);
      },
      /**
       * 选择选项
       */
      selectMulti:function (seldata){
        this.ckdata.selid=[];
        for (var i=0;i<seldata.length;i++){
          this.ckdata.selid.push(seldata[i].id);
        }
      },
      /**
       * 批量操作
       */
      examWith:function (id,status){
        if(id!=-1) this.ckdata.selid=[id];
        if(this.ckdata.selid.length==0) return this.alertError('请选择要审核的提现！');
        this.ckdata.ckstatus=status;
        this.ckdata.reason='';
        this.showM=true;
      },
      /**
       * 提交审核数据
       */
      examCom:function (){
        var _this=this;
        if(this.ckdata.ckstatus==4 && this.ckdata.reason==''){
          this.examMLoading = false;
          setTimeout(function (){_this.examMLoading = true;},5);
          return this.alertError('请填写拒绝原因！');
        }
        this.requestApi('/adm/withdraw_exam.html',this.ckdata).then(function (res){
          if(res.status==1){
            _this.init();
            _this.showM =false;
            _this.tipSuccess(res.msg);
          }else{
            _this.examMLoading = false;
            setTimeout(function (){_this.examMLoading = true;},5);
            _this.alertError(res.msg);
          }
        })
      },
      /**
       * 查看发票信息
       */
      showInvmsg:function (key){
        this.invdata.invdata=this.data.data[key].invdata;
        this.invdata.invtype=this.data.data[key].invtype;
        this.invdata.invtotm=this.data.data[key].invtotm;
        this.showInv=true;
      },
      /**
       * 选择时间
       */
      seldate:function (event,key){
        this.posdata[key]=event;
      },
      /**
       * 导出Excel
       */
      export_excel:function (){
        let _this=this,title={
          id:'会员ID',
          wx_name:'用户昵称',
          user_phone:'用户手机',
          gender:'性别',
          level_id:'等级',
          account:'提现账号',
          realname:'提现姓名',
          num:'身份证号',
          wmoney:'提现金额',
          wfee:'手续费',
          truemoney:'实到账金额',
          account_typetxt:'账户类型',
          bank_name:'银行名称',
          mobile:'预留手机',
          name:'真实姓名',
          statustxt:'提现状态',
          add_time:'申请时间',
          remark:'备注'
        };
        this.export_loading=true;
        this.requestExcel('/adm/export_withdraw.html',title,this.posdata).then(function (res){
          _this.export_loading=false;
          if(res.status!=1) return _this.alertError(res.msg);
          const sheet=XLSX.utils.aoa_to_sheet(res.data);
          const book = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(book, sheet, 'Sheet1');
          XLSX.writeFile(book, '提现列表.xlsx');
        });
      }
		}
	}
</script>

<style lang="less">
@import "~@/assets/css/finance/withdraw";
</style>